import BGPH1 from '../../assets/images/backgrounds/Calculators.png'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import React from 'react'
import { Helmet } from "react-helmet";

const Careers = () => {

    let history = useNavigate()
    const location = useLocation()
    const path = location.pathname

    return (
        <>
           
            <Helmet>
                <title>Welcome To - Addon Broker</title>
                <meta charset="UTF-8" />
                <meta name="description" content="Addon Brokers has created a name for itself in the financial management segment of Australia, offering a wide range of accounting and financial solutions ranging from accounting, conveyancing, financial planning and mortgage broking." />
                {/* <meta name="keywords" content="HTML, CSS, JavaScript" /> */}
                <link rel="canonical" href={path + "/"} />
                <script type="application/ld+json">
          {`
{
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Addon Brokers",
  "image" : [ "https://www.addonbrokers.com.au/static/media/logo-5.62c44e50737ebc04df33.png", "https://www.addonbrokers.com.au/" ],
  "telephone": "+61 0397924747",
  "email": "info@addonbrokers.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "5/50 Thomas Street",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.addonbrokers.com.au${path}"
}`}
        </script>
            </Helmet>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>

                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Student Essentials</span></li> */}
                    </ul>
                    <h2>Calculators</h2>
                </div>
            </section>




            <section className="career-one pt-60 pb-120">
                <div className="container">
                    {/* <div className="block-title text-center">
                    <p className="block-title__tagline">What We’re Offering</p>
                    <h2 className="block-title__title">Checkout finlon new job <br /> opportunities </h2>
                </div> */}
                    <div className="row row-gutter-y-10">


                        <div className="col-12 col-lg-3 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">

                            <a href='https://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Calculator/' target='_blank' >
                                <div className="career-one__item4">
                                    <div className="career-one__icon">
                                        <i className="icon-flowchart"></i>
                                    </div>
                                    <div className="career-one__content">
                                        {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Student Essentials/' target='_blank' > */}
                                        <h3 className="career-one__title"

                                        >Home Loan Calculator</h3>
                                        {/* </a> */}

                                        <p className="career-one__text" style={{ color: "#68686F", fontSize: "14px" }}>Our clients’ aims vary as per their needs of buying a new home, moving home, refinance or invest in property, but our aims are constant. </p>

                                        {/* <p className="career-one__text" style={{ color: "#68686F" }}>With our in-depth knowledge of various academic disciplines and institutions worldwide, we assist you in selecting the best courses and universities that align with your interests and career aspirations. Your dreams are our priority, and we're committed to guiding you toward the educational opportunities that will shape your future</p> */}
                                    </div>
                                    {/* <div className="career-one__btns d-flex justify-content-center align-items-end" >
                                    <Link to="/contact" className="thm-btn">Calculate Now</Link>
                                </div> */}
                                    <div className='d-flex justify-content-center'>
                                        <div style={{ fontSize: "14px", background: "#005257", padding: "5px 35px", color: "white", borderRadius: "20px", width: "170px" }} >Calculate Now</div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-lg-3 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">

                            <a href='https://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Stamp_Duty_Calculator/' target='_blank' >
                                <div className="career-one__item4">
                                    <div className="career-one__icon">
                                        <i className="icon-flowchart"></i>
                                    </div>
                                    <div className="career-one__content">
                                        {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Student Essentials/' target='_blank' > */}
                                        <h3 className="career-one__title">Stamp Duty Calculator</h3>
                                        {/* </a> */}

                                        <p className="career-one__text" style={{ color: "#68686F", fontSize: "14px" }}>Our clients’ aims vary as per their needs of buying a new home, moving home, refinance or invest in property, but our aims are constant. </p>

                                        {/* <p className="career-one__text" style={{ color: "#68686F" }}>With our in-depth knowledge of various academic disciplines and institutions worldwide, we assist you in selecting the best courses and universities that align with your interests and career aspirations. Your dreams are our priority, and we're committed to guiding you toward the educational opportunities that will shape your future</p> */}
                                    </div>
                                    {/* <div className="career-one__btns d-flex justify-content-center align-items-end" >
                                    <Link to="/contact" className="thm-btn">Calculate Now</Link>
                                </div> */}
                                    <div className='d-flex justify-content-center'>
                                        <div style={{ fontSize: "14px", background: "#005257", padding: "5px 35px", color: "white", borderRadius: "20px", width: "170px" }} >Calculate Now</div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-lg-3 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">

                            <a href='https://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Saving_Calculator/' target='_blank' >
                                <div className="career-one__item4">
                                    <div className="career-one__icon">
                                        <i className="icon-flowchart"></i>
                                    </div>
                                    <div className="career-one__content">
                                        {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Student Essentials/' target='_blank' > */}
                                        <h3 className="career-one__title" style={{ whiteSpace: "nowrap" }} >Savings Target Calculator</h3>
                                        {/* </a> */}

                                        <p className="career-one__text" style={{ color: "#68686F", fontSize: "14px" }}>Our clients’ aims vary as per their needs of buying a new home, moving home, refinance or invest in property, but our aims are constant. </p>

                                        {/* <p className="career-one__text" style={{ color: "#68686F" }}>With our in-depth knowledge of various academic disciplines and institutions worldwide, we assist you in selecting the best courses and universities that align with your interests and career aspirations. Your dreams are our priority, and we're committed to guiding you toward the educational opportunities that will shape your future</p> */}
                                    </div>
                                    {/* <div className="career-one__btns d-flex justify-content-center align-items-end" >
                                    <Link to="/contact" className="thm-btn">Calculate Now</Link>
                                </div> */}
                                    <div className='d-flex justify-content-center'>
                                        <div style={{ fontSize: "14px", background: "#005257", padding: "5px 35px", color: "white", borderRadius: "20px", width: "170px" }} >Calculate Now</div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-lg-3 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">

                            <a href='https://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Calculator/' target='_blank' >
                                <div className="career-one__item4">
                                    <div className="career-one__icon">
                                        <i className="icon-flowchart"></i>
                                    </div>
                                    <div className="career-one__content">
                                        {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Student Essentials/' target='_blank' > */}
                                        <h3 className="career-one__title">Interest Comparison</h3>
                                        {/* </a> */}

                                        <p className="career-one__text" style={{ color: "#68686F", fontSize: "14px" }}>Our clients’ aims vary as per their needs of buying a new home, moving home, refinance or invest in property, but our aims are constant. </p>

                                        {/* <p className="career-one__text" style={{ color: "#68686F" }}>With our in-depth knowledge of various academic disciplines and institutions worldwide, we assist you in selecting the best courses and universities that align with your interests and career aspirations. Your dreams are our priority, and we're committed to guiding you toward the educational opportunities that will shape your future</p> */}
                                    </div>
                                    {/* <div className="career-one__btns d-flex justify-content-center align-items-end" >
                                    <Link to="/contact" className="thm-btn">Calculate Now</Link>
                                </div> */}
                                    <div className='d-flex justify-content-center'>
                                        <div style={{ fontSize: "14px", background: "#005257", padding: "5px 35px", color: "white", borderRadius: "20px", width: "170px" }} >Calculate Now</div>
                                    </div>

                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="call-to-action-two">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-6 wow fadeInLeft"
                            data-wow-delay="000ms"
                            data-wow-duration="1500ms"
                        >
                            <div className="call-to-action-two__image">
                                <img src="assets/images/backgrounds/cta-2-1-bg.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="call-to-action-two__content">
                                <ul className="list-unstyled call-to-action-two__list">
                                    <li>Simple</li>
                                    <li>Transparent</li>
                                    <li>Secure</li>
                                </ul>
                                <h3 className="call-to-action-two__title">
                                    Call us today to discuss your financial needs
                                </h3>

                                {/* <Link to="/applynow" className="thm-btn">
                                    Schedule An Appointment
                                </Link> */}


                                <div className='d-flex' >
                                    <a href="tel:0397924747" className='thm-btn3 main-menu__btn2' target='_blank' style={{ background: "white", color: "black", display: "flex", alignItems: "center", width: "220px" }} >
                                        <div style={{
                                            background: "#05B4BC",
                                            borderRadius: "100%",
                                            padding: "10px"
                                        }}>
                                            <i className="icon-telephone" style={{ color: "white" }} ></i>
                                        </div>
                                        &ensp;0397 924 747
                                    </a>

                                    <span className="call-to-action-two__arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 130.49 76.07"
                                        >
                                            <path
                                                d="M153.74,101.43l1.56-.21c1-.12,2.49-.38,4.44-.53s4.28-.35,7-.49l4.29-.07c1.51,0,3.1.05,4.75.13,3.31.18,6.89.43,10.65,1a116,116,0,0,1,11.73,2.27,123,123,0,0,1,12.26,3.66,120.32,120.32,0,0,1,23.84,11.47,113.33,113.33,0,0,1,10.53,7.41c1.61,1.33,3.21,2.62,4.7,4s2.93,2.69,4.31,4,2.62,2.73,3.87,4,2.34,2.67,3.39,4,2.06,2.55,2.93,3.8,1.74,2.41,2.48,3.54l2,3.21c.61,1,1.12,2,1.6,2.8s.9,1.61,1.22,2.29l.84,1.73c.45.93.68,1.43.68,1.43a.95.95,0,0,1-1.62,1l0,0s-.33-.44-.93-1.27-1.43-2.06-2.59-3.57l-1.87-2.52c-.71-.89-1.47-1.86-2.28-2.9-1.63-2.06-3.55-4.32-5.68-6.75-1.07-1.21-2.24-2.41-3.4-3.71s-2.44-2.56-3.79-3.82c-2.61-2.62-5.53-5.2-8.62-7.8-.77-.65-1.58-1.26-2.38-1.91s-1.61-1.28-2.45-1.88l-2.52-1.88L232.07,122a126.44,126.44,0,0,0-11-6.71,117.91,117.91,0,0,0-11.65-5.54,106.37,106.37,0,0,0-11.85-4c-1-.29-2-.54-2.93-.77l-2.88-.69c-1.93-.37-3.8-.79-5.65-1-3.68-.63-7.2-.93-10.45-1.16-1.63-.09-3.19-.1-4.67-.15l-4.22.06-3.7.21c-1.14.07-2.18.22-3.12.31-1.87.17-3.37.44-4.38.6l-1.56.24a.94.94,0,0,1-1.08-.78,1,1,0,0,1,.79-1.09h0"
                                                transform="translate(-152.92 -100.13)"
                                            />
                                            <path
                                                d="M280.58,151.16c-.13,1-.19,1.94-.26,2.9s-.06,1.92-.07,2.89a50.5,50.5,0,0,0,.37,5.77c.12,1,.27,1.91.44,2.86s.38,1.89.6,2.83c.47,1.86,1,3.7,1.65,5.51a1.71,1.71,0,0,1-2.18,2.18l-.26-.09-2.46-.85c-.82-.28-1.64-.55-2.45-.85s-1.64-.57-2.44-.87l-2.44-.89c-1.61-.6-3.21-1.27-4.78-2a33,33,0,0,1-4.62-2.58,1.07,1.07,0,0,1-.25-1.44,1,1,0,0,1,.8-.48h0a21.85,21.85,0,0,1,2.7.26c.88.13,1.76.3,2.62.51a37.62,37.62,0,0,1,5.08,1.54q2.48.93,4.89,2c1.61.73,3.19,1.52,4.75,2.35l-2.44,2.09c-.18-1-.33-2-.47-3s-.23-2-.34-3-.17-2-.25-3-.13-2-.18-3-.07-2-.09-3l0-3c0-1-.06-2,0-3l0-3v0a1,1,0,0,1,1.08-.91,1,1,0,0,1,1,1s0,0,0,.08"
                                                transform="translate(-152.92 -100.13)"
                                            />
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="career-about pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-xl-start text-center">
                            <img src="assets/images/resources/career-about-1-1.jpg" className="img-responsive wow fadeInLeft" data-wow-duration="1500ms" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="career-about__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">Small business loans for daily expenses</h2>
                                </div>
                                <p className="career-about__highlight">Duis irure dolor lipsum is simply free text available.</p>

                                <p className="career-about__text">There are many variations of passages of lorem ipsum available the
                                    majority have suffered alteration in some form by injected humour. Duis aute irure dolor lipsum
                                    is simply free text available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
}

export default Careers