import { Link } from 'react-router-dom'
import React from 'react'

import LOGO from "../../assets/images/logo-6.png"
import COMPANY from "../../assets/images/INFINITYCS.png"

const Footer = () => {
    return (
        <>
            <footer className="main-footer">
                <div className="container">
                    <div className="row row-gutter-y-30">
                        <div className="col-xl-3 col-md-6">
                            <div className="footer-widget footer-widget--about" style={{ marginRight: "40px" }}>
                                <Link to="/" className="footer-widget__logo">
                                    <img src={LOGO} alt="finlon" width="160" height="85" />
                                </Link>
                                <p className="footer-widget__text">Addon Brokers has created a name for itself in the financial management segment of Australia, offering a wide range of accounting and financial solutions ranging from accounting, conveyancing, financial planning and mortgage broking.</p>
                                {/* <ul className="list-unstyled footer-widget__info">
                                    <li>
                                        <i className="icon-email"></i>
                                        <a href='mailto:info@learnexcel.com.au' >info@learnexcel.com.au </a>
                                    </li>
                                    <li>
                                        <i className="icon-telephone"></i>
                                        <a href="tel:0397924747">0397 924 747	</a>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-6">
                            <div className="footer-widget footer-widget--links">
                                <h3 className="footer-widget__title">
                                    Explore
                                </h3>
                                <ul className="list-unstyled footer-widget__menu">
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/loan-options">Loan Options</Link></li>
                                    {/* <li><Link to="/courses">Courses</Link></li> */}
                                    {/* <li><Link to="/servicedetails">Service Details</Link></li> */}
                                    <li><Link to="/calculator">Calculators</Link></li>
                                    <li><Link to="/our-team">Our Team</Link></li>
                                    {/* <li><Link to="/essentials">Student Essentials</Link></li> */}
                                    {/* <li><Link to="/applynow">Schedule An Appointment</Link></li> */}
                                    <li><Link to="/contact">Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="footer-widget footer-widget--time">
                                <h3 className="footer-widget__title">
                                    Opening Hours
                                </h3>
                                <p className="footer-widget__text">
                                    <div className="row row-gutter-y-30">
                                        <div className="col d-flex flex-column"
                                            style={{
                                                maxWidth: "130px"
                                            }}
                                        >

                                            <div>Monday </div>
                                            <div>Tuesday </div>
                                            <div>Wednesday </div>
                                            <div>Thursday </div>
                                            <div>Friday</div>
                                            <div>Saturday</div>
                                            <div>Sunday</div>

                                        </div>
                                        <div className="col d-flex flex-column">

                                            <div>: 9:00am - 5:00pm </div>
                                            <div>: 9:00am - 5:00pm </div>
                                            <div>: 9:00am - 5:00pm </div>
                                            <div>: 9:00am - 5:00pm </div>
                                            <div>: 9:00am - 5:00pm </div>
                                            <div>: 9:00am - 5:00pm </div>
                                            <div>: 9:00am - 5:00pm </div>
                                        </div>
                                    </div>

                                    {/* <div className='mt-4'>Pre appointments only</div> */}


                                    {/* <br/> Saturday: 9:00am - 5:00pm <br /> Sunday: Closed */}
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget footer-widget--newsletter">
                                <h3 className="footer-widget__title">
                                    Contact
                                </h3>
                                <ul className="list-unstyled footer-widget__info">
                                    <li>
                                        <i className="icon-pin"></i>
                                        <a href='' >5/50 Thomas Street Dandenong
                                            VIC 3175</a>
                                    </li>
                                    <li>
                                        <i className="icon-email"></i>
                                        <a href='mailto:info@addonbrokers.com.au' >info@addonbrokers.com.au </a>
                                    </li>
                                    <li>
                                        <i className="icon-telephone"></i>
                                        <a href="tel:0397924747">0397 924 747</a>
                                    </li>
                                </ul>
                                <div className="footer-widget__social">
                                    {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}


                                    <a href='https://www.facebook.com/addonbrokers?mibextid=2JQ9oc' target='_blank' >
                                        <i className="fab fa-facebook"></i>
                                    </a>

                                    <a href='https://www.instagram.com/addonbrokermelbourne?igsh=NTc4MTIwNjQ2YQ==' target='_blank' >
                                        <i className="fab fa-instagram"></i>
                                    </a>

                                    {/* <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                </div>
                                {/* <form className="footer-widget__mailchimp">
                                    <input type="email" placeholder="Email address" />
                                    <p className="footer-widget__mailchimp__text">
                                        <i className="fa fa-check"></i>
                                        I agree to all your terms and policies
                                    </p>
                                </form> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6">
                            <div className="footer-widget footer-widget--newsletter">
                                <h3 className="footer-widget__title">
                                    Newsletter
                                </h3>
                                <form className="footer-widget__mailchimp">
                                    <input type="email" placeholder="Email address" />
                                    <p className="footer-widget__mailchimp__text">
                                        <i className="fa fa-check"></i>
                                        I agree to all your terms and policies
                                    </p>
                                </form>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>
            <div className="bottom-footer">
                <div className="container">
                    <p className="bottom-footer__text text-center">Copyright & Designed By &ensp;
                        <a href='https://infinitixx.com/' target='_blank' >
                            <img src={COMPANY} alt="company" style={{ height: "43px", marginTop: "0px" }} />
                        </a>

                    </p>

                </div>
            </div>
        </>
    )
}

export default Footer