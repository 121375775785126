import BGPH1 from '../../../assets/images/backgrounds/OurTeam.png'
import { Link, useLocation } from 'react-router-dom'
import React from 'react'

import TEAM1 from '../../../assets/images/team/team/1.png'
import TEAM2 from '../../../assets/images/team/team/2.png'
import TEAM3 from '../../../assets/images/team/team/3.png'
import TEAM4 from '../../../assets/images/team/team/4.png'
import TEAM5 from '../../../assets/images/team/team/5.png'
import TEAM6 from '../../../assets/images/team/team/6.png'
import TEAM7 from '../../../assets/images/team/team/7.png'
import TEAM8 from '../../../assets/images/team/team/8.png'
import TEAM9 from '../../../assets/images/team/team/9.png'
import TEAM10 from '../../../assets/images/team/team/10.png'
import TEAM11 from '../../../assets/images/team/team/11.png'
import TEAM12 from '../../../assets/images/team/team/12.png'
import TEAM13 from '../../../assets/images/team/team/13.png'
import TEAM14 from '../../../assets/images/team/team/14.png'
import TEAM15 from '../../../assets/images/team/team/15.png'
import TEAM16 from '../../../assets/images/team/team/16.png'
import TEAM17 from '../../../assets/images/team/team/17.png'
import TEAM18 from '../../../assets/images/team/team/18.png'
import TEAM19 from '../../../assets/images/team/team/19.png'
import TEAM20 from '../../../assets/images/team/team/20.png'
import TEAM21 from '../../../assets/images/team/team/21.jpg'
import TEAM23 from '../../../assets/images/team/team/23.jpg'
import { Helmet } from "react-helmet";


const Team = () => {
    const location = useLocation()
    const path = location.pathname
    return (
        <>

            <Helmet>
                <title>Welcome To - Addon Broker</title>
                <meta charset="UTF-8" />
                <meta name="description" content="Addon Brokers has created a name for itself in the financial management segment of Australia, offering a wide range of accounting and financial solutions ranging from accounting, conveyancing, financial planning and mortgage broking." />
                {/* <meta name="keywords" content="HTML, CSS, JavaScript" /> */}
                <link rel="canonical" href={path + "/"} />
                <script type="application/ld+json">
                    {`
{
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Addon Brokers",
  "image" : [ "https://www.addonbrokers.com.au/static/media/logo-5.62c44e50737ebc04df33.png", "https://www.addonbrokers.com.au/" ],
  "telephone": "+61 0397924747",
  "email": "info@addonbrokers.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "5/50 Thomas Street",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.addonbrokers.com.au${path}"
}`}
                </script>
            </Helmet>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    {/* <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Team</span></li>
                </ul> */}
                    <h2>Our Team</h2>
                </div>
            </section>

            <section className="team-grid pt-120 pb-120">
                <div className="container">
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM1} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Shajin Kesikkaran
                                        </h3>
                                        <p className="team-card__designation">East Melbourne Region
                                        </p>
                                        <p className="mt-4">Addon Brokers

                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            0470 615 323

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            info@addonbrokers.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM2} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Tarun Parmar
                                        </h3>
                                        <p className="team-card__designation">South East Melbourne Region


                                        </p>
                                        <p className="mt-4">Addon Brokers

                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>

                                            0424 274 237

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            info@addonbrokers.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM3} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Dr.Shaji Varghese

                                        </h3>
                                        <p className="team-card__designation">Melbourne Region




                                        </p>
                                        <p className="mt-4">Pheonix Financial Services


                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>


                                            0401 865 790

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            info@phoenixfinancialservices.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM4} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Paul Mathew


                                        </h3>
                                        <p className="team-card__designation">South East Melbourne Region

                                        </p>
                                        <p className="mt-4">4u Loans

                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>



                                            0424 942 690

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            paul@4uloans.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM5} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Ranjit Singh

                                        </h3>
                                        <p className="team-card__designation">North Melbourne Region

                                        </p>
                                        <p className="mt-4">Punjab Loan Centre

                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>




                                            0413 752 058

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            bajwa@addonbrokers.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM6} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                     
                                        // <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        // <Link to="#"><i className="fab fa-linkedin"></i></Link>
                                    
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Raman Singh


                                        </h3>
                                        <p className="team-card__designation">East Melbourne Region



                                        </p>
                                        <p className="mt-4">Armahi Loans


                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>


                                            0435 772 271

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            loans@armahiloans.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM7} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Pradeep Srivastava

                                        </h3>
                                        <p className="team-card__designation">East Melbourne Region

                                        </p>
                                        <p className="mt-4">Srivas Finance

                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>



                                            0412 639 703

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            pradeep@addonbrokers.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM8} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Jatinder Pal Singh


                                        </h3>
                                        <p className="team-card__designation">South East Melbourne Region



                                        </p>
                                        <p className="mt-4">Aus X Finance


                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>




                                            0422 019 655

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            jatinder.singh@addonbrokers.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM9} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                       
                                        // <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        // <Link to="#"><i className="fab fa-linkedin"></i></Link>
                                       
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Sunny Singh Sehdev

                                        </h3>
                                        <p className="team-card__designation">South East Melbourne Region


                                        </p>
                                        <p className="mt-4">Instant Mortgage Brokers


                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>





                                            0433 600 745

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            sunny@instantmortgagebrokers.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM10} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                      
                                        // <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        // <Link to="#"><i className="fab fa-linkedin"></i></Link>
                                       
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Kaumil Patel


                                        </h3>
                                        <p className="team-card__designation">Melbourne Region




                                        </p>
                                        <p className="mt-4">Lending R Us



                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>



                                            0497 505 757

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            lendingrus@outlook.com

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM11} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Mital Shah



                                        </h3>
                                        <p className="team-card__designation">South East Melbourne Region






                                        </p>
                                        <p className="mt-4">Addon Brokers




                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>

                                            0425 146 115

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            mital.shah@addonbrokers.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM12} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                     
                                        // <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        // <Link to="#"><i className="fab fa-linkedin"></i></Link>
                                       
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Manvinder Singh

                                        </h3>
                                        <p className="team-card__designation">South East Melbourne Region

                                        </p>
                                        <p className="mt-4">Armaan Loans


                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>


                                            0422 267 126

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            manvinder@addonbrokers.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM13} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Gurdev Dhanoa


                                        </h3>
                                        <p className="team-card__designation">South East Melbourne Region



                                        </p>
                                        <p className="mt-4">Better Finance


                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>



                                            0433 967 175

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            gurdev.dhanoa@addonbrokers.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM14} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Jayesh Dangodara

                                        </h3>
                                        <p className="team-card__designation">South East Melbourne Region


                                        </p>
                                        <p className="mt-4">Dpoint Mortgage

                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>




                                            0433 450 150

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            jayesh@dpointmortgage.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM15} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        
                                        // <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        // <Link to="#"><i className="fab fa-linkedin"></i></Link>
                                        
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Sukhwant Singh


                                        </h3>
                                        <p className="team-card__designation">West Melbourne Region




                                        </p>
                                        <p className="mt-4">Addon Brokers


                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>





                                            0433 810 576

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            sukhwant@addonbrokers.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM16} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                       
                                        // <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        // <Link to="#"><i className="fab fa-linkedin"></i></Link>
                                       
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Bobish Jose


                                        </h3>
                                        <p className="team-card__designation">South East Melbourne Region


                                        </p>
                                        <p className="mt-4">AU Kart Loans

                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>






                                            0431 043 000

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            loans@aukartloans.com.au

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM17} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Shagoun Vaid

                                        </h3>
                                        <p className="team-card__designation">Casey & Cardinia Region

                                        </p>
                                        <p className="mt-4">Book A Broker

                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>







                                            0413 529 112

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            info@bookabroker.com.au

                                        </p>
                                        {/* <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            loans@aukartloans.com.au

                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM18} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                       
                                        // <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        // <Link to="#"><i className="fab fa-linkedin"></i></Link>
                                       
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Purushottam Budhathoki


                                        </h3>
                                        <p className="team-card__designation">North Melbourne Region



                                        </p>
                                        <p className="mt-4">Addon Brokers


                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>








                                            0430 271 783

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            puru@addonbrokers.com.au

                                        </p>
                                       
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM19} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Navjot Singh



                                        </h3>
                                        <p className="team-card__designation">Wyndham Region





                                        </p>
                                        <p className="mt-4">Addon Brokers



                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>


                                            0422 900 007

                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            navjotsingh@addonbrokers.com.au

                                        </p>
                                        {/* <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            loans@aukartloans.com.au

                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM21} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Dhrupal Patel





                                        </h3>
                                        <p className="team-card__designation">South East Region








                                        </p>
                                        <p className="mt-4 invisible">Addon Brokers



                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>



                                            0469 615 413


                                        </p>
                                        <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            dhrupal@sachiandsachiloans.com.au

                                        </p>
                                        {/* <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            loans@aukartloans.com.au

                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM23} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Sukhjinder Singh





                                        </h3>
                                        <p className="team-card__designation">South East Melbourne Region








                                        </p>
                                        <p className="mt-4">Loanverse



                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>



                                            0433 268 202


                                        </p>
                                        {/* <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            dhrupal@sachiandsachiloans.com.au

                                        </p> */}
                                        {/* <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            loans@aukartloans.com.au

                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xl-3 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src={TEAM20} alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-facebook"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-linkedin"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-pinterest"></i></Link> */}
                                        {/* <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                    </div>
                                </div>
                                <div className="//team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title">Bindiya Patel







                                        </h3>
                                        <p className="team-card__designation">Western Melbourne Region








                                        </p>
                                        <p className="mt-4">Goldendust Finance





                                        </p>
                                        <p style={{ fontSize: "15px", marginTop: "-14px", color: "black", fontWeight: "500" }}>



                                            0426 487 441




                                        </p>
                                        {/* <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            dhrupal@sachiandsachiloans.com.au

                                        </p> */}
                                        {/* <p style={{ fontSize: "12.5px", marginTop: "-14px", color: "black", fontWeight: "500" }}>
                                            loans@aukartloans.com.au

                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
}

export default Team