import BGPH1 from '../../assets/images/backgrounds/ContactUs2.png'
import { Link, useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import { postContacts } from '../../Services/API/Post'

import MAP1 from '../../assets/images/contact/map1.png'
import MAP2 from '../../assets/images/contact/map2.png'
import { Helmet } from "react-helmet";


const Contact = () => {
    const location = useLocation()
    const path = location.pathname

    const [msg, setmsg] = React.useState(null)

    const [firstName, setfirstName] = useState("");
    const [subject, setsubject] = useState("");
    const [email, setemail] = useState("");
    const [message, setmessage] = useState("");
    const [mobile, setmobile] = useState("");

    const [errfirstName, seterrfirstName] = useState(false);
    const [errsubject, seterrsubject] = useState(false);
    const [errmessage, seterrmessage] = useState(false);
    const [erremail, seterremail] = useState(false);
    const [errmobile, seterrmobile] = useState("");


    React.useEffect(() => {
        if (msg !== null) {
            setTimeout(() => {
                setmsg(null);
            }, 6000);
        }

    }, [msg])

    const contactUs = (event) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;


        event.preventDefault();

        const obj = {
            name: firstName,
            email: email,
            subject: subject,
            message: message,
            contactNumber: mobile,
        };

        console.log("obj", obj);

        if (firstName === "") {
            seterrfirstName(true);
        }
        if (subject === "") {
            seterrsubject(true);
        }
        if (email === "") {
            seterremail(true);
        }

        if (message === "") {
            seterrmessage(true);
        }

        if (!phoneRegex.test(mobile)) {
            if (mobile === "") {
                seterrmobile("This field is required");
            } else {
                seterrmobile("Please enter a valid phone number");
            }
        }


        if (
            firstName !== "" &&
            email !== "" &&
            subject !== "" &&
            message !== "" &&
            mobile !== "" &&
            phoneRegex.test(mobile)
        ) {
            postContacts(obj)
                .then((res) => {
                    console.log("res", res);
                    setmsg("Your enquiry has been successfully submitted")

                    setfirstName("");
                    setemail("")
                    setsubject("")
                    setmessage("")

                    // alert("Submited..!!")
                    // this.setState(
                    //   {
                    //     name: "",
                    //     email: "",
                    //     contact:"",
                    //     memberNo:"",
                    //     webSite: "",
                    //     message: "",
                    //     toaster: true,
                    //     msg: "Your enquiry has been successfully submitted",
                    //   }
                    //   //   () => {
                    //   //     setTimeout(this.setState({toaster: false}),4000);
                    //   //   }
                    // );
                })
                .catch((error) => {
                    console.log("error", error);
                    // setmsg(error.response.data.message)
                    // setTimeout(setmsg(null),4000);
                    // this.setState({});
                });

        }



    };

    return (
        <>

            <Helmet>
                <title>Welcome To - Addon Broker</title>
                <meta charset="UTF-8" />
                <meta name="description" content="Addon Brokers has created a name for itself in the financial management segment of Australia, offering a wide range of accounting and financial solutions ranging from accounting, conveyancing, financial planning and mortgage broking." />
                {/* <meta name="keywords" content="HTML, CSS, JavaScript" /> */}
                <link rel="canonical" href={path + "/"} />
                <script type="application/ld+json">
          {`
{
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Addon Brokers",
  "image" : [ "https://www.addonbrokers.com.au/static/media/logo-5.62c44e50737ebc04df33.png", "https://www.addonbrokers.com.au/" ],
  "telephone": "+61 0397924747",
  "email": "info@addonbrokers.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "5/50 Thomas Street",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.addonbrokers.com.au${path}"
}`}
        </script>
            </Helmet>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Contact</span></li> */}
                    </ul>
                    <h2>Contact us</h2>
                </div>
            </section>


            <section className="contact-one pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="contact-one__content">
                                <div className="block-title">

                                    <h2 className="block-title__title">Request a Call Back</h2>
                                </div>
                                <p className="contact-one__text">Quickly complete this form and submit it to us. One of our experienced financial consultants will contact you shortly.
                                </p>


                            </div>
                        </div>


                        <div className="col-lg-8">
                            <form className="form-one contact-form-validated" onSubmit={contactUs} >
                                <div className="row row-gutter-y-20 row-gutter-x-20">
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Full name" name="name"
                                            value={firstName}
                                            onChange={(e) => {
                                                setfirstName(e.target.value);
                                                seterrfirstName(false);
                                            }}
                                        />
                                        {errfirstName && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" placeholder="Email address" name="email"
                                            value={email}
                                            onChange={(e) => {
                                                setemail(e.target.value);
                                                seterremail(false);
                                            }} />
                                        {erremail && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Phone number" name="phone"
                                            value={mobile}
                                            onChange={(e) => {
                                                setmobile(e.target.value);
                                                seterrmobile("");
                                            }}
                                        />
                                        {errmobile && (
                                            <div className="error" style={{ color: "red" }}>
                                                {errmobile}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Subject" name="subject"
                                            value={subject}
                                            onChange={(e) => {
                                                setsubject(e.target.value);
                                                seterrsubject(false);
                                            }}
                                        />
                                        {errsubject && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <textarea placeholder="Message" name="message"
                                            value={message}
                                            onChange={(e) => {
                                                setmessage(e.target.value);
                                                seterrmessage(false);
                                            }}
                                        ></textarea>
                                        {errmessage && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    {msg && (
                                        <span className="error" style={{ color: "green" }} >{msg}</span>
                                    )}
                                    <div className="col-md-12 d-flex align-items-center">

                                        {/* <button type="submit" className="thm-btn thm-btn--dark-hover" style={{ width: "200px" }}>Send Message</button> */}

                                        <button type="submit" style={{ fontSize: "14px", background: "#005257", padding: "5px 35px", color: "white", borderRadius: "20px" }} >Submit Now</button>

                                        {/* <h6 className="contact-info-one__link pl-20 pr-20">Or</h6> */}

                                        {/* <button type="submit" className="thm-btn2">Schedule Appointment</button> */}
                                        {/* <a href='https://calendly.com/learnexcel/30min' className='thm-btn' target='_blank'>Schedule An Appointment</a> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>


            <section className="contact-info-one">
                <div className="container">
                    <div className="row d-flex justify-content-start align-items-center">
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-telephone"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Home Loan Enquiries</p>
                                    <a className="contact-info-one__link" href="tel:1300 755 626" >1300 755 626</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-telephone"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Mortgage Brokering </p>
                                    <a className="contact-info-one__link" href="tel:1300 755 626" >1300 755 626</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-email"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Write us email</p>
                                    <a className="contact-info-one__link" href="mailto:info@addonbrokers.com.au">info@addonbrokers.com.au</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-pin"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Head Office</p>
                                    <a className="contact-info-one__link" href="#">5/50 Thomas Street Dandenong VIC 3175
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-pin"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Branch</p>
                                    <a className="contact-info-one__link" href="#">3/40B Wallace Avenue Point Cook VIC 3030

                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>



            {/* <a href="https://maps.app.goo.gl/A5htM42WE5LKEsVY8" target="_blank" style={{ cursor: "pointer" }}>

                <div class="about-two__image2 d-none d-sm-block">
                    <img src={MAP1} alt="" style={{ width: "100%" }} />

                </div>

                <div class="about-two__image2 d-block d-sm-none">
                    <img src={MAP2} alt="" style={{ width: "100%" }} />

                </div>

            </a> */}

            {/* <div className="google-map__default">
                <iframe title="template google map" src="https://maps.google.com/maps?q=No 78/29A Wackwella Road,Galle 80000&t=&z=15&ie=UTF8&iwloc=&output=embed" className="map__default" allowfullscreen></iframe>
            </div> */}

            {/* <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}

        </>
    )
}

export default Contact