import BGPH1 from '../../../assets/images/backgrounds/Services2.png'
import BGS1 from '../../../assets/images/services/service-s-1-1.png'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import SERVICE1 from '../../../assets/images/services/InHomeServices.png'
import { Helmet } from "react-helmet";


const ServiceDetails = () => {
    const [clicked, setClicked] = useState(0);
    const faqs = [
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Quisque quis urna consequat, scelerisque",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Mauris a ipsum id libero sodales dapibus",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat.There are many variations of passages of available but the majority have suffered alteration that some form by injected randomised words which don’t look even as slightly believable now. Phasellus mollis ac augue sed varius. "
        },
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        }
    ]
    return (
        <>
            <Helmet>
                <title>Expert Conveyancer in Melbourne | Trustworthy Solutions - Addon Brokers</title>
                <meta charset="UTF-8" />
                <meta name="description" content="Looking for reliable conveyancer in Melbourne? Our experienced team offers expert assistance for all your property transfer needs." />
                <meta name="keywords" content="conveyancer melbourne" />
                <link rel="canonical" href="/conveyancing/" />
                <script type="application/ld+json">
          {`
{
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Addon Brokers",
  "image" : [ "https://www.addonbrokers.com.au/static/media/logo-5.62c44e50737ebc04df33.png", "https://www.addonbrokers.com.au/" ],
  "telephone": "+61 0397924747",
  "email": "info@addonbrokers.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "5/50 Thomas Street",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.addonbrokers.com.au/conveyancing/"
}`}
        </script>
            </Helmet>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Courses</span></li> */}
                    </ul>
                    <h2>Conveyancing
                    </h2>
                </div>
            </section>
            <section className="service-details pt-80 pb-120">
                <div className="container">
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__item service-sidebar__item--menu">
                                    <ul className="service-sidebar__menu">
                                        <li ><Link to="/mortage-brokers-melbourne">Mortage Brokering</Link></li>
                                        <li ><Link to="/financial-advisor-melbourne">Financial Planning</Link></li>
                                        <li className="active"><Link to="/conveyancing">Conveyancing</Link></li>
                                        <li><Link to="/accounting">Accounting</Link></li>
                                        <li><Link to="/self-managed-super-funds">Self-managed Super Funds</Link></li>
                                        <li><Link to="/mortage-insurance">Mortgage Insurance</Link></li>

                                    </ul>
                                </div>
                                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                                    <div className="service-sidebar__bg" style={{ backgroundImage: `url(${BGS1})` }}>
                                    </div>
                                    <div className="service-sidebar__contact">
                                        <div className="service-sidebar__icon">
                                            <i className="icon-phone-ringing"></i>
                                        </div>
                                        <h3 className="service-sidebar__title">Quick loan
                                            proccess</h3>
                                        <hr className="service-sidebar__separator" />
                                        <p className="service-sidebar__tagline">Talk to an expert</p>
                                        <a href='tel:0397924747' className="service-sidebar__phone">0397 924 747</a>

                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            {/* <div className="service-details__image">
                                <img src={SERVICE1} alt="" />
                               
                            </div> */}
                            <div className="service-details__content">
                                <h1 className="service-details__title">Expert Conveyancer Services in Melbourne. Your Key to Smooth Property Transactions</h1>
                                <p>Are you looking for professional conveyancing services in Melbourne? Look no further than Addon Brokers! With years of experience in the Australian property market, our team of expert conveyancers specializes in facilitating smooth property transfers in Melbourne and its surrounding areas.</p>
                                <p>At Addon Brokers, we understand that conveyancing involves navigating through a maze of legal requirements and procedures. Our licensed conveyancers are well-versed in the intricacies of Victorian state laws and council regulations, ensuring that your property transaction adheres to all necessary guidelines.</p>
                                <p>Whether you're buying, selling, or transferring property in Melbourne, Addon Brokers offers comprehensive end-to-end conveyancing solutions tailored to your specific needs. Our services cover every aspect of the conveyancing process, including but not limited to:</p>
                                {/* <p>We make hundreds of loans available for our clients even from the mainstream banks in the country. We help you to choose the best and most suitable loans for your requirements. Taking a loan is the most significant financial decision you take in your life, but with our expert direction and guidance, it is simple and very fruitful.</p> */}
                                {/* <p>List of documents typically required for the loan. These documents may vary depending on the lender, loan type, and individual circumstances. It's always best to consult with us for the most accurate and up-to-date information regarding the required documentation.</p> */}



                                <div className="col-md-12 pt-20">


                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Researching and checking for encumbrances and
                                                restrictions on the property

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Ensuring special conditions mentioned in the
                                                contract are met
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Ensuring rates, land tax and water consumption
                                                charges are paid by the appropriate party


                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Organising for the payment of fees, charges and
                                                government duties

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Preparation of legal documents

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Liaising with banks/financiers on behalf of the
                                                purchaser

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Liaising with the banks/financiers on behalf of the
                                                vendor to arrange settlement and obtain discharge
                                                of mortgages on the title

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Organising and attending final settlement
                                            </span>
                                        </li>


                                    </ul>
                                </div>

                                <p className='pt-20'>At Addon Brokers, we are dedicated to simplifying the conveyancing process, allowing our clients to focus on their property goals with confidence. Trust us to handle your conveyancing needs in Melbourne, and experience a hassle-free journey from start to finish.</p>
                                <p>Contact Addon Brokers today to learn more about our tailored conveyancing solutions and take the first step towards a successful property transaction in Melbourne.</p>




                                {/* <div className="row row-gutter-y-30">
                                    <div className="col-md-6">
                                        <img src="assets/images/services/service-d-1-2.png" alt="" />
                                    </div>
                                    <div className="col-md-6">
                                        <h3 className="service-details__subtitle">Service benefits</h3>
                                        <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                            esse cillum.</p>
                                        <ul className="list-unstyled ml-0 service-details__list">
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Refresing to get such a personal touch.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Duis aute irure dolor in in voluptate.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Velit esse cillum eu fugiat pariatur.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Duis aute irure dolor in in voluptate.
                                            </span>
                                                </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
                                            {faqs.map((item, index) => (
                                                <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                                    <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                        <h4>{item.question}</h4>
                                                        <span className="accrodion-icon"></span>
                                                    </div>
                                                    {index === clicked && <div className="accrodion-content">
                                                        <div className="inner">
                                                            <p>{item.answer}</p>
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="call-to-action-two">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-6 wow fadeInLeft"
                            data-wow-delay="000ms"
                            data-wow-duration="1500ms"
                        >
                            <div className="call-to-action-two__image">
                                <img src="assets/images/backgrounds/cta-2-1-bg.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="call-to-action-two__content">
                                <ul className="list-unstyled call-to-action-two__list">
                                    <li>Simple</li>
                                    <li>Transparent</li>
                                    <li>Secure</li>
                                </ul>
                                <h3 className="call-to-action-two__title">
                                    Call us today to discuss your financial needs
                                </h3>

                                {/* <Link to="/applynow" className="thm-btn">
                                    Schedule An Appointment
                                </Link> */}


                                <div className='d-flex' >
                                    <a href="tel:0397924747" className='thm-btn3 main-menu__btn2' target='_blank' style={{ background: "white", color: "black", display: "flex", alignItems: "center", width: "220px" }} >
                                        <div style={{
                                            background: "#05B4BC",
                                            borderRadius: "100%",
                                            padding: "10px"
                                        }}>
                                            <i className="icon-telephone" style={{ color: "white" }} ></i>
                                        </div>
                                        &ensp;0397 924 747
                                    </a>

                                    <span className="call-to-action-two__arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 130.49 76.07"
                                        >
                                            <path
                                                d="M153.74,101.43l1.56-.21c1-.12,2.49-.38,4.44-.53s4.28-.35,7-.49l4.29-.07c1.51,0,3.1.05,4.75.13,3.31.18,6.89.43,10.65,1a116,116,0,0,1,11.73,2.27,123,123,0,0,1,12.26,3.66,120.32,120.32,0,0,1,23.84,11.47,113.33,113.33,0,0,1,10.53,7.41c1.61,1.33,3.21,2.62,4.7,4s2.93,2.69,4.31,4,2.62,2.73,3.87,4,2.34,2.67,3.39,4,2.06,2.55,2.93,3.8,1.74,2.41,2.48,3.54l2,3.21c.61,1,1.12,2,1.6,2.8s.9,1.61,1.22,2.29l.84,1.73c.45.93.68,1.43.68,1.43a.95.95,0,0,1-1.62,1l0,0s-.33-.44-.93-1.27-1.43-2.06-2.59-3.57l-1.87-2.52c-.71-.89-1.47-1.86-2.28-2.9-1.63-2.06-3.55-4.32-5.68-6.75-1.07-1.21-2.24-2.41-3.4-3.71s-2.44-2.56-3.79-3.82c-2.61-2.62-5.53-5.2-8.62-7.8-.77-.65-1.58-1.26-2.38-1.91s-1.61-1.28-2.45-1.88l-2.52-1.88L232.07,122a126.44,126.44,0,0,0-11-6.71,117.91,117.91,0,0,0-11.65-5.54,106.37,106.37,0,0,0-11.85-4c-1-.29-2-.54-2.93-.77l-2.88-.69c-1.93-.37-3.8-.79-5.65-1-3.68-.63-7.2-.93-10.45-1.16-1.63-.09-3.19-.1-4.67-.15l-4.22.06-3.7.21c-1.14.07-2.18.22-3.12.31-1.87.17-3.37.44-4.38.6l-1.56.24a.94.94,0,0,1-1.08-.78,1,1,0,0,1,.79-1.09h0"
                                                transform="translate(-152.92 -100.13)"
                                            />
                                            <path
                                                d="M280.58,151.16c-.13,1-.19,1.94-.26,2.9s-.06,1.92-.07,2.89a50.5,50.5,0,0,0,.37,5.77c.12,1,.27,1.91.44,2.86s.38,1.89.6,2.83c.47,1.86,1,3.7,1.65,5.51a1.71,1.71,0,0,1-2.18,2.18l-.26-.09-2.46-.85c-.82-.28-1.64-.55-2.45-.85s-1.64-.57-2.44-.87l-2.44-.89c-1.61-.6-3.21-1.27-4.78-2a33,33,0,0,1-4.62-2.58,1.07,1.07,0,0,1-.25-1.44,1,1,0,0,1,.8-.48h0a21.85,21.85,0,0,1,2.7.26c.88.13,1.76.3,2.62.51a37.62,37.62,0,0,1,5.08,1.54q2.48.93,4.89,2c1.61.73,3.19,1.52,4.75,2.35l-2.44,2.09c-.18-1-.33-2-.47-3s-.23-2-.34-3-.17-2-.25-3-.13-2-.18-3-.07-2-.09-3l0-3c0-1-.06-2,0-3l0-3v0a1,1,0,0,1,1.08-.91,1,1,0,0,1,1,1s0,0,0,.08"
                                                transform="translate(-152.92 -100.13)"
                                            />
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Link to="/servicedetails" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}
        </>
    )
}

export default ServiceDetails