import BGPH1 from '../../../assets/images/backgrounds/LoanOptions.png'
import BGS1 from '../../../assets/images/services/service-s-1-1.png'
import { Link, useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import SERVICE1 from '../../../assets/images/services/InHomeServices.png'
import ICON1 from '../../../assets/images/icons/1.png'
import ICON2 from '../../../assets/images/icons/2.png'
import ICON3 from '../../../assets/images/icons/3.png'
import ICON4 from '../../../assets/images/icons/4.png'
import ICON5 from '../../../assets/images/icons/5.png'
import ICON6 from '../../../assets/images/icons/6.png'

import ICON10 from '../../../assets/images/icons/10.png'
import ICON11 from '../../../assets/images/icons/11.png'
import ICON12 from '../../../assets/images/icons/12.png'
import ICON13 from '../../../assets/images/icons/13.png'
import { Helmet } from "react-helmet";

const ServiceDetails = () => {
    const location = useLocation()
    const path = location.pathname
    const [clicked, setClicked] = useState(0);
    const faqs = [
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Quisque quis urna consequat, scelerisque",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Mauris a ipsum id libero sodales dapibus",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat.There are many variations of passages of available but the majority have suffered alteration that some form by injected randomised words which don’t look even as slightly believable now. Phasellus mollis ac augue sed varius. "
        },
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        }
    ]
    return (
        <>

            <Helmet>
                <title>Welcome To - Addon Broker</title>
                <meta charset="UTF-8" />
                <meta name="description" content="Addon Brokers has created a name for itself in the financial management segment of Australia, offering a wide range of accounting and financial solutions ranging from accounting, conveyancing, financial planning and mortgage broking." />
                {/* <meta name="keywords" content="HTML, CSS, JavaScript" /> */}
              <link rel="canonical" href={path + "/"} />
              <script type="application/ld+json">
          {`
{
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Addon Brokers",
  "image" : [ "https://www.addonbrokers.com.au/static/media/logo-5.62c44e50737ebc04df33.png", "https://www.addonbrokers.com.au/" ],
  "telephone": "+61 0397924747",
  "email": "info@addonbrokers.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "5/50 Thomas Street",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.addonbrokers.com.au${path}"
}`}
        </script>
            </Helmet>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Courses</span></li> */}
                    </ul>
                    <h2>Loan Options
                    </h2>
                </div>
            </section>
            <section className="benefit-one" style={{ position: "relative", zIndex: 12, marginTop: "-40px" }} >
                {/* <div
                    className="benefit-one__shape-1 wow fadeInLeft"
                    data-wow-delay="000ms"
                    data-wow-duration="1500ms"
                    style={{ backgroundImage: `url(${BGImgBe1})` }}
                ></div> */}
                {/* <div className="benefit-one__shape-2"></div> */}
                <div className="container">
                    <div className="row row-gutter-y-60">
                        {/* <div className="col-lg-6">
                            <div
                                className="benefit-one__image wow fadeInUp"
                                data-wow-duration="1500ms"
                                data-wow-delay="500ms"
                            >
                                <img src="assets/images/resources/benefit-1-1.png" alt="" />
                                <div className="benefit-one__image__caption">
                                    <h3 className="benefit-one__image__title">99.9%</h3>
                                    <p className="benefit-one__image__text">
                                        Success Rates Guarantee
                                    </p>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-12">
                            <div className="benefit-one__content">
                                {/* <div className="block-title text-center">
                                    <p className="block-title__tagline">WHY WE ARE THE BEST</p>
                                    <h2 className="block-title__title">
                                        Why you should choose our company
                                    </h2>
                                </div> */}

                                {/* <p className="benefit-one__text">
                                    Nullam vel nibh facilisis lectus fermentum ultrices quis non
                                    risus. Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit. In hac habitasse platea dictumst. Duis porta, quam ut
                                    finibus ultrices, lorem lacus congue lorem et rutrum sapien
                                    magna tincidunt magna.
                                </p> */}
                                {/* <div className="benefit-one__box">
                                    <div className="benefit-one__box__icon">
                                        <i className="icon-bank"></i>
                                    </div>
                                    <div className="benefit-one__box__content">
                                        <h3 className="benefit-one__box__title">
                                            Lowest bank fees
                                        </h3>
                                        <p className="benefit-one__box__text">
                                            Lorem ipsum dolor consectetur notte massa sapien samet.
                                            Aucibus sed sem non, mattis commodo nisi.
                                        </p>
                                    </div>
                                </div>
                                <div className="benefit-one__box">
                                    <div className="benefit-one__box__icon">
                                        <i className="icon-payment"></i>
                                    </div>
                                    <div className="benefit-one__box__content">
                                        <h3 className="benefit-one__box__title">
                                            Up to 20.000$ limit
                                        </h3>

                                        <p className="benefit-one__box__text">
                                            Lorem ipsum dolor consectetur notte massa sapien samet.
                                            Aucibus sed sem non, mattis commodo nisi.
                                        </p>
                                    </div>
                                </div>
                                <div className="benefit-one__box">
                                    <div className="benefit-one__box__icon">
                                        <i className="icon-smartphone-1"></i>
                                    </div>
                                    <div className="benefit-one__box__content">
                                        <h3 className="benefit-one__box__title">Easy in 3 steps</h3>
                                        <p className="benefit-one__box__text">
                                            Lorem ipsum dolor consectetur notte massa sapien samet.
                                            Aucibus sed sem non, mattis commodo nisi.
                                        </p>
                                    </div>
                                </div> */}
                                <div className="row d-flex justify-content-center" style={{ padding: "0px 0px", background: "white" }} >

                                    <div className='' style={{ background: "#05B4BC", height: "20px", }} >

                                    </div>

                                    <div className='row row-gutter-x-0 d-flex justify-content-center' >

                                        <div className="col-12 col-lg-3 wow fadeInUp d-flex justify-content-center" data-wow-duration="1500ms" data-wow-delay="000ms" >

                                            <Link to={"/loan-options"} >
                                                <div className="career-one__item5">

                                                    <img src={ICON10} className="mb-30" width={60} />
                                                    <div className="career-one__content">

                                                        <h3 className="career-one__title" style={{ color: "#05B4BC" }} >Home Loans</h3>

                                                    </div>

                                                </div>
                                            </Link>


                                        </div>

                                        <div className="col-12 col-lg-3 d-flex " data-wow-duration="1500ms" data-wow-delay="000ms">

                                            <div class="d-flex" style={{ padding: "30px 0px" }} >
                                                <div className="vr" style={{ color: "#Abacb0", }} ></div>
                                            </div>



                                            <div className='d-flex justify-content-center' style={{ width: "100%" }} >
                                                <Link to={"/re-financing-loans"} >

                                                    <div className="career-one__item5">

                                                        <img src={ICON11} className="mb-30" width={60} />
                                                        <div className="career-one__content">

                                                            <h3 className="career-one__title"  >Re-Financing Loans</h3>

                                                        </div>

                                                    </div>
                                                </Link>
                                            </div>





                                        </div>

                                        <div className="col-12 col-lg-3 wow fadeInUp d-flex justify-content-center" data-wow-duration="1500ms" data-wow-delay="000ms">


                                            <div class="d-flex" style={{ padding: "30px 0px" }}>
                                                <div className="vr" style={{ color: "#Abacb0", }}></div>
                                            </div>

                                            <div className='d-flex justify-content-center' style={{ width: "100%" }} >

                                                <Link to={"/investment-loans"} >

                                                    <div className="career-one__item5">

                                                        <img src={ICON12} className="mb-30" width={60} />
                                                        <div className="career-one__content">

                                                            <h3 className="career-one__title">Investment Loans</h3>

                                                        </div>

                                                    </div>
                                                </Link>
                                            </div>



                                        </div>

                                        <div className="col-12 col-lg-3 wow fadeInUp  d-flex justify-content-center" data-wow-duration="1500ms" data-wow-delay="000ms">

                                            <div className='d-flex justify-content-center' style={{ width: "100%" }} >
                                                <div class="d-flex" style={{ padding: "30px 0px" }}>
                                                    <div className="vr" style={{ color: "#Abacb0", }} ></div>
                                                </div>



                                                <div className='d-flex justify-content-center' style={{ width: "100%" }} >
                                                    <Link to={"/commercial-loans"} >

                                                        <div className="career-one__item5">

                                                            <img src={ICON13} className="mb-30" width={60} />
                                                            <div className="career-one__content">

                                                                <h3 className="career-one__title">Commercial Loans</h3>

                                                            </div>

                                                        </div>
                                                    </Link>

                                                </div>



                                            </div>

                                        </div>

                                    </div>



                                    <div className="col-lg-12">
                                        {/* <ul className="list-unstyled ml-0 about-two__list "
                                            style={{
                                                marginLeft: "-30px"
                                            }}
                                        >
                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}>Tailored and Comprehensive Services</b> <br /><span style={{ fontWeight: 400 }}>
                                                    We stand out by offering many services under one roof for individual needs. Our comprehensive approach ensures that everyone receives personalized care, fostering a holistic and effective support system.

                                                </span>
                                            </li>
                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}> 6 Years of Expertise</b>  <br /> <span style={{ fontWeight: 400 }}>
                                                    Benefit from our wealth of experience garnered over six years of dedicated service. Our seasoned team has successfully worked with various conditions, including Down syndrome, intellectual disabilities, autism, Angelman's syndrome, behaviours of concern, physical disabilities, and more.
                                                </span>
                                            </li>
                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}>Client-Centered Approach</b> <br /><span style={{ fontWeight: 400 }}>At Glorious Support Services, our commitment revolves around you. We prioritize your goals, preferences, and unique requirements, creating a client-centered approach that places your well-being and aspirations at the forefront of our services.

                                                </span>
                                            </li>
                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}>Empowering Through Choice</b>  <br /><span style={{ fontWeight: 400 }}>We believe in the power of choice and options. Our dedicated team works collaboratively with you to ensure that you have the information and support needed to make informed decisions about your life, empowering you to lead a life of your choosing.

                                                </span>
                                            </li>

                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}>Community Engagement Initiatives</b>  <br /><span style={{ fontWeight: 400 }}>Beyond individual care, we actively engage in community initiatives. Through social events, activities, and support networks, we foster a sense of belonging and connection, promoting a supportive community environment for our clients.

                                                </span>
                                            </li>

                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}>Passionate Team of Professionals</b>  <br /><span style={{ fontWeight: 400 }}>Our success is driven by our passionate and skilled team of professionals. From experienced speech therapists to dedicated physiotherapists, our staff is committed to enhancing the lives of individuals with disabilities, bringing expertise, compassion, and enthusiasm to every interaction.

                                                </span>
                                            </li>
                                        </ul> */}

                                        {/* <div className="col-md-6">
        <div className="about-five__video">
            <img src="assets/images/resources/about-5-v-1.png" alt="" />
            <Link to="#" onClick={() => setytShow(true)} className="video-popup about-five__video__btn">
                <i className="fa fa-play"></i>
                <span className="ripple"></span>
            </Link>
        </div>
    </div> */}
                                    </div>
                                    {/* <div className="col-md-6">
    <div className="about-one__box">
        <div className="about-one__box__icon">
            <i className="icon-money-transfer"></i>
        </div>
        <div className="about-one__box__content">
            <h3 className="about-one__box__title">International banking
                & finance</h3>
            <p className="about-one__box__text">Nullam mollis elit quis dus is lacinia not efficitur
            </p>
        </div>
    </div>
</div>
<div className="col-md-6">
    <div className="about-one__box">
        <div className="about-one__box__icon">
            <i className="icon-bank-transfer"></i>
        </div>
        <div className="about-one__box__content">
            <h3 className="about-one__box__title">International banking
                & finance</h3>
            <p className="about-one__box__text">Nullam mollis elit quis dus is lacinia not efficitur
            </p>
        </div>
    </div>
</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="service-details pt-40 pb-120">
                <div className="container">
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__item service-sidebar__item--menu">
                                    <ul className="service-sidebar__menu">
                                        <li className="active"><Link to="/loan-options">Guide to Refinancing</Link></li>
                                        <li ><Link to="/loan-process">Loan Process</Link></li>
                                        <li ><Link to="/document-checklist">Document Checklist</Link></li>
                                        <li ><Link to="/refinancing-faqs">Refinancing FAQs</Link></li>
                                        {/* <li> <Link to="/self-managed-super-funds">Self-managed Super Funds</Link></li>
                                        <li><Link to="/mortage-insurance">Mortgage Insurance</Link></li> */}

                                    </ul>
                                </div>
                                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                                    <div className="service-sidebar__bg" style={{ backgroundImage: `url(${BGS1})` }}>
                                    </div>
                                    <div className="service-sidebar__contact">
                                        <div className="service-sidebar__icon">
                                            <i className="icon-phone-ringing"></i>
                                        </div>
                                        <h3 className="service-sidebar__title">Quick loan
                                            proccess</h3>
                                        <hr className="service-sidebar__separator" />
                                        <p className="service-sidebar__tagline">Talk to an expert</p>
                                        <a href='tel:0397924747' className="service-sidebar__phone">0397 924 747</a>

                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            {/* <div className="service-details__image">
                                <img src={SERVICE1} alt="" />
                               
                            </div> */}
                            <div className="service-details__content">
                                {/* <h3 className="service-details__title">Self-managed Super Funds</h3> */}
                                <p>Refinancing lets you change your home loan to suit your new circumstances.</p>
                                {/* <p>We make hundreds of loans available for our clients even from the mainstream banks in the country. We help you to choose the best and most suitable loans for your requirements. Taking a loan is the most significant financial decision you take in your life, but with our expert direction and guidance, it is simple and very fruitful.</p> */}
                                {/* <p>List of documents typically required for the loan. These documents may vary depending on the lender, loan type, and individual circumstances. It's always best to consult with us for the most accurate and up-to-date information regarding the required documentation.</p> */}


                                {/* <p>Many people believe superannuation is an investment but it can be best described as a TAX SYSTEM. If money or investments are set aside for retirement and you meet certain legislative requirements, then you qualify for tax concessions.</p> */}

                                <div className="col-md-12 pt-20">

                                    <p className="service-details__text">Why Refinance your Home Loan or Mortgage?</p>

                                    <p>If you’re no longer happy with your current lender or loan, or your financial situation has changed, it’s time to refinance your existing mortgage.</p>
                                    <p>Common reasons for refinancing your home loan, investment loan or commercial mortgage include:
                                    </p>

                                    <ul className="list-unstyled ml-0 service-details__list mt-4">

                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                <b>Home renovations</b> such as refurbishment, remodelling, installing a pool or adding outbuildings.

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                <b>Saving money</b> by refinancing to a mortgage with a lower interest rate or or reduced fee,  and
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                <b>Accessing new loan features</b> better suited to your current situation.


                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                <b>Debt consolidation</b> to pay off credit cards, store cards and other loans by rolling them into your home loan.


                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                <b>Accessing equity</b> in your home to purchase a car,overseas travel, buy an investment property and other purposes.


                                            </span>
                                        </li>
                                        {/* <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Lodgement of Annual Payment summaries

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Tax planning

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Tax returns filing

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Tax returns amendment
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                FBT returns filing

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Home loans
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Re-financing
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Investment loans
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Commercial loans
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                First home buyers grant
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Non confirming lending
                                            </span>
                                        </li> */}



                                    </ul>
                                </div>


                                <div className="col-md-12 pt-20">

                                    <p className="service-details__text">What are the options to be considered?

                                    </p>

                                    <p>New products are emerging rapidly in the mortgage industray. Mortgage Consultants at PJ Home Loans can help you find a loan and professionally package it so that it suits your current financial circumstances and particular needs. See the the below section for the types of home loan products to be considered:
                                    </p>

                                    <ul className="list-unstyled ml-0 about-two__list pb-40">
                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                Variable Rate


                                            </b>
                                            <br />
                                            Standard variable loans are one of the most popular home loans in Australia. Interest rates can go up or down over the life off the loan depending on the official rate set by the Reserve Bank of Australia and funding costs. Your regular loan repayments pay off both the interest and some of the principal. With Standard Variable Rate Loans, you are allowed to make extra repayments. Even small extra payments can cut the length and cost of your mortgage significantly.
                                            <br />
                                            <br />
                                            One of the disadvantages of having a variable rate home loan is increased loan repayments due to rate rises could impact your household budget. You can also choose a basic variable loan, which offers a discounted interest rate but has fewer loan features, such as a redraw facility and repayment flexibility.


                                        </li>

                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                Fixed Rate

                                            </b>
                                            <br />
                                            With Fixed Rate Home Loans, the interest rate is fixed for a certain period, usually the first one to five years of the loan. This means your regular repayments stay the same regardless of changes in interest rates. At the end of the fixed period, you can either fix the rate again, at whatever rate your lenders are offering at the time, or move to a variable loan. See our Guides and Tips section to compare the Pros and Cons of Fixed Rate Loans.

                                        </li>

                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                Split Rate Loans

                                            </b>
                                            <br />
                                            Many customers opt for Split Rate Loans where your loan amount is split, so one part is variable, and the other is fixed. Customers get the flexibility on choosing the proportion of variable and fixed. The good part about Split Rate Loans is that you enjoy some of the flexibility of a variable loan along with the certainty of a fixed rate loan.

                                        </li>

                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                Interest Only

                                            </b>
                                            <br />
                                            With Interest Only Loans customers need to repay only the interest on the amount borrowed usually for the first one to five years of the loan, although some lenders offer longer terms. Your monthly repayments are lower since you are not also paying off the principal. At the end of the interest-only period, you begin to pay off both interest and principal. These loans are especially popular with investors who plan to pay off the principal when the property is sold, having achieved capital growth. Our Tips and Guides section will give you a good idea of the Pros and Cons of Interest Only loans.

                                        </li>

                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                Line Of Credit

                                            </b>
                                            <br />
                                            With Line of Credit Loans, you can pay into and withdraw from your home loan every month, so long as you keep up the regular required repayments. Many people choose to have their salary paid into their Line of Credit account. With Line of Credit Loans, you can use your income to help reduce interest charges and pay off your mortgage quickly.  This type of loan is good for people who want maximum flexibility in their access to funds. One of the disadvantages of Line of Credit Loans are, they usually carry slightly higher interest rates.

                                        </li>
                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                Introductory/Honeymoon

                                            </b>
                                            <br />
                                            Introductory loans offer a discounted interest rate for the first 6 to 12 months, before the rate reverts to the usual variable interest rate. This product was originally designed for first-home buyers, but now available more widely to many types of loans. Most of the lenders offers Introductory/Honeymoon loans, so that customers get lower regular repayments in the initial period to avoid financial.

                                        </li>
                                        <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                            {/* <i className="fa fa-arrow-circle-right"></i> */}
                                            <b>
                                                Low Doc


                                            </b>
                                            <br />
                                            Low Doc Loans are popular with self-employed people. These loans require less documentation but often carry higher interest rates or require a larger deposit because of the perceived higher lender risk. In most cases you will be financially better off getting together full documentation for another type of loan. But if this isn’t possible, a low doc loan may be your best opportunity to borrow money.


                                        </li>



                                    </ul>



                                </div>





                                {/* <div className="row row-gutter-y-30">
                                    <div className="col-md-6">
                                        <img src="assets/images/services/service-d-1-2.png" alt="" />
                                    </div>
                                    <div className="col-md-6">
                                        <h3 className="service-details__subtitle">Service benefits</h3>
                                        <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                            esse cillum.</p>
                                        <ul className="list-unstyled ml-0 service-details__list">
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Refresing to get such a personal touch.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Duis aute irure dolor in in voluptate.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Velit esse cillum eu fugiat pariatur.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Duis aute irure dolor in in voluptate.
                                            </span>
                                                </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
                                            {faqs.map((item, index) => (
                                                <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                                    <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                        <h4>{item.question}</h4>
                                                        <span className="accrodion-icon"></span>
                                                    </div>
                                                    {index === clicked && <div className="accrodion-content">
                                                        <div className="inner">
                                                            <p>{item.answer}</p>
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="call-to-action-two">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-6 wow fadeInLeft"
                            data-wow-delay="000ms"
                            data-wow-duration="1500ms"
                        >
                            <div className="call-to-action-two__image">
                                <img src="assets/images/backgrounds/cta-2-1-bg.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="call-to-action-two__content">
                                <ul className="list-unstyled call-to-action-two__list">
                                    <li>Simple</li>
                                    <li>Transparent</li>
                                    <li>Secure</li>
                                </ul>
                                <h3 className="call-to-action-two__title">
                                    Call us today to discuss your financial needs
                                </h3>

                                {/* <Link to="/applynow" className="thm-btn">
                                    Schedule An Appointment
                                </Link> */}


                                <div className='d-flex' >
                                    <a href="tel:0397924747" className='thm-btn3 main-menu__btn2' target='_blank' style={{ background: "white", color: "black", display: "flex", alignItems: "center", width: "220px" }} >
                                        <div style={{
                                            background: "#05B4BC",
                                            borderRadius: "100%",
                                            padding: "10px"
                                        }}>
                                            <i className="icon-telephone" style={{ color: "white" }} ></i>
                                        </div>
                                        &ensp;0397 924 747
                                    </a>

                                    <span className="call-to-action-two__arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 130.49 76.07"
                                        >
                                            <path
                                                d="M153.74,101.43l1.56-.21c1-.12,2.49-.38,4.44-.53s4.28-.35,7-.49l4.29-.07c1.51,0,3.1.05,4.75.13,3.31.18,6.89.43,10.65,1a116,116,0,0,1,11.73,2.27,123,123,0,0,1,12.26,3.66,120.32,120.32,0,0,1,23.84,11.47,113.33,113.33,0,0,1,10.53,7.41c1.61,1.33,3.21,2.62,4.7,4s2.93,2.69,4.31,4,2.62,2.73,3.87,4,2.34,2.67,3.39,4,2.06,2.55,2.93,3.8,1.74,2.41,2.48,3.54l2,3.21c.61,1,1.12,2,1.6,2.8s.9,1.61,1.22,2.29l.84,1.73c.45.93.68,1.43.68,1.43a.95.95,0,0,1-1.62,1l0,0s-.33-.44-.93-1.27-1.43-2.06-2.59-3.57l-1.87-2.52c-.71-.89-1.47-1.86-2.28-2.9-1.63-2.06-3.55-4.32-5.68-6.75-1.07-1.21-2.24-2.41-3.4-3.71s-2.44-2.56-3.79-3.82c-2.61-2.62-5.53-5.2-8.62-7.8-.77-.65-1.58-1.26-2.38-1.91s-1.61-1.28-2.45-1.88l-2.52-1.88L232.07,122a126.44,126.44,0,0,0-11-6.71,117.91,117.91,0,0,0-11.65-5.54,106.37,106.37,0,0,0-11.85-4c-1-.29-2-.54-2.93-.77l-2.88-.69c-1.93-.37-3.8-.79-5.65-1-3.68-.63-7.2-.93-10.45-1.16-1.63-.09-3.19-.1-4.67-.15l-4.22.06-3.7.21c-1.14.07-2.18.22-3.12.31-1.87.17-3.37.44-4.38.6l-1.56.24a.94.94,0,0,1-1.08-.78,1,1,0,0,1,.79-1.09h0"
                                                transform="translate(-152.92 -100.13)"
                                            />
                                            <path
                                                d="M280.58,151.16c-.13,1-.19,1.94-.26,2.9s-.06,1.92-.07,2.89a50.5,50.5,0,0,0,.37,5.77c.12,1,.27,1.91.44,2.86s.38,1.89.6,2.83c.47,1.86,1,3.7,1.65,5.51a1.71,1.71,0,0,1-2.18,2.18l-.26-.09-2.46-.85c-.82-.28-1.64-.55-2.45-.85s-1.64-.57-2.44-.87l-2.44-.89c-1.61-.6-3.21-1.27-4.78-2a33,33,0,0,1-4.62-2.58,1.07,1.07,0,0,1-.25-1.44,1,1,0,0,1,.8-.48h0a21.85,21.85,0,0,1,2.7.26c.88.13,1.76.3,2.62.51a37.62,37.62,0,0,1,5.08,1.54q2.48.93,4.89,2c1.61.73,3.19,1.52,4.75,2.35l-2.44,2.09c-.18-1-.33-2-.47-3s-.23-2-.34-3-.17-2-.25-3-.13-2-.18-3-.07-2-.09-3l0-3c0-1-.06-2,0-3l0-3v0a1,1,0,0,1,1.08-.91,1,1,0,0,1,1,1s0,0,0,.08"
                                                transform="translate(-152.92 -100.13)"
                                            />
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Link to="/servicedetails" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}
        </>
    )
}

export default ServiceDetails